import { EditOutlined, DeleteOutlined, EyeOutlined, LeftSquareOutlined, FileSyncOutlined } from '@ant-design/icons'
import { Tooltip , Card, Skeleton, Button } from 'antd'
import React, {useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {DATAMODEL} from '../../database'
import { CreateModal } from '../modals/CreateModal'
import { DeleteModal } from '../modals/DeleteModal'
import { EditModal } from '../modals/EditModal'
import { PDFExportModal } from '../modals/ExportPdfModal'
import { DuplicateDrawingModal } from '../modals/DuplicateDrawingModal'
import './Explorer.css'
import fabric from "../../fabric";
import ImageLoader from '../shared/ImageLoader'

const { Meta } = Card

export const Drawings = (props) => {
  const [loadingDrawings, setLoadingDrawings] = React.useState(true)
  const [drawings, setDrawings] = React.useState([])
  const [createModal, setCreateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [duplicateModal, setDuplicateModal] = React.useState(false)
  const [editModal, setEditModal] = React.useState(false)
  const [modalLoading, setModalLoading] = React.useState(false);
  const [currentDrawing, setCurrentDrawing] = React.useState(false)
  const [project, setProject] = React.useState({})
  const [pageOption, setPageOption] = useState(true)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const params = useParams();
  const navigate = useNavigate()
  const resource = "drawing";

  useEffect(() => {
    const { id } = params
    fetchProject(id)
  },[params])

  const toggleCreateDrawingModal = () => {
      setCreateModal(!createModal)
  }

  const toggleDeleteDrawingModal = () => {
      setDeleteModal(!deleteModal)
  }

  const toggleDuplicateDrawingModal = () => {
      setDuplicateModal(!duplicateModal)
  }

  const toggleEditDrawingModal = () => {
      setEditModal(!editModal)
  }

  const handleDuplicateDrawing = async (name, description, projectId) => {
    setModalLoading(true)
    await DATAMODEL.duplicateDrawing(currentDrawing.id, projectId, {name, description});
    setModalLoading(false)
    setDuplicateModal(false)
    await fetchDrawings(projectId)
  }

  const handleDeleteDrawing = async () => {
    setModalLoading(true)
    await DATAMODEL.deleteDrawing(currentDrawing.id);
    setModalLoading(false)
    setDeleteModal(false)
    await fetchDrawings(params.id)
  }

  const handleEditDrawing = async (name, description) => {
    setModalLoading(true)
    await DATAMODEL.editDrawingInfo(currentDrawing.id, {name, description});
    setModalLoading(false)
    setEditModal(false)
    await fetchDrawings(params.id)
  }

  const handleCreateDrawing = async (name, description) => {
    const { id: projectId } = params
    setCreateModal(false)
    setLoadingDrawings(true)
    setModalLoading(true)
    let newDrawing =  await DATAMODEL.createDrawing( projectId, {name, description});
    setModalLoading(false)
    await fetchDrawings(params.id)
    // navigate(`/board/${projectId}/${newDrawing.id}`)
  }

  const fetchProject = async(projectId) => {
    setLoadingDrawings(true)
    let project = await DATAMODEL.project(projectId)
    setProject(project)
    setDrawings(project.drawings)
    setLoadingDrawings(false)
  }

  const fetchDrawings = async (projectId) => {
    setLoadingDrawings(true)
    let newDrawings = await DATAMODEL.drawings(projectId)
    setDrawings(newDrawings)
    setLoadingDrawings(false)
  }

  const openDrawing = (drawing) => {
    const { id: projectId } = params
    const { id: pageId } = drawing
    navigate(`/board/${projectId}/${pageId}`)
  }

  const togglePDFModal = () => {
    setShowPdfModal(!showPdfModal)
  }

  const exportProjectAsPDF = async () => {
    togglePDFModal()
    await fabric.createProjectPDF(project.name,drawings,pageOption)
  }
  
  return (
    <div className='DashboardContainer'>
      <div className='drawingsContainer'>
        {
          project &&
          <div className='header-block'>
            <h2><Link to="/"><LeftSquareOutlined className="backBtn" /></Link>{project.name} Project</h2>
            <Button
              onClick={togglePDFModal}
              type='primary'
              size='medium'
              className='manage-cat-button'
            >
              Export Project as PDF
            </Button>
          </div>
        }
        <div className='drawings'>
          {

            loadingDrawings ?
              <div>Loading Drawings</div>
              :
              <div className="drawings-inner">
                <Tooltip title="Create new Drawing">
                  <Card
                    key={0}
                    onClick={toggleCreateDrawingModal}
                    title="Create New Drawing"
                    className="newDrawingCard drawingCard"
                  >
                    <img
                      alt="new-drawing"
                      src={window.location.origin + `/img/new-drawing.png`}
                    />
                  </Card>
                </Tooltip>
                {
                  drawings.map((drawing, index) =>
                    <React.Fragment key={index}>
                      <Card
                        key={index}
                        className="drawingCard"
                        title={drawing.name}
                        cover={
                          <div className="drawing-image-preview" style={{ cursor: 'pointer' }} onClick={() => {
                            openDrawing(drawing)
                          }}>
                            {
                              <ImageLoader drawing={drawing.id} version={drawing.drawingVersion} />
                              // drawing.imageUrl ?
                              // <img className='svg-drawing' alt='example' src={drawing.imageUrl}/> :
                              // <img className="svg-drawing" alt="example" src={getImageURL(drawing)} />

                              // https://firebasestorage.googleapis.com/v0/b/iso-easy.appspot.com/o/drawings%2FMLsiYeLYN5Hs0p6j1hpa%2Fimage?alt=media&token=fd60761b-96e3-4574-9362-352df8454436
                              // <img className='svg-drawing' alt='example' src='/img/sample-drawing.png'/>
                            }
                          </div>
                        }
                        actions={[
                          <Tooltip title="Edit Details">
                            <EditOutlined key="edit"
                                          onClick={() => {
                                            setCurrentDrawing(drawing)
                                            toggleEditDrawingModal()
                                          }}
                            />
                          </Tooltip>,
                          <Tooltip title="Duplicate Drawing">
                            <FileSyncOutlined key="duplicate"
                                              onClick={() => {
                                                setCurrentDrawing(drawing)
                                                toggleDuplicateDrawingModal()
                                              }}
                            />
                          </Tooltip>,
                          <Tooltip title="Delete">
                            <DeleteOutlined key="delete" tooltip="Delete"
                                            onClick={() => {
                                              setCurrentDrawing(drawing)
                                              toggleDeleteDrawingModal()
                                            }}
                            />
                          </Tooltip>,
                          <Tooltip title="View Drawing">
                            <EyeOutlined key="view"
                                         onClick={() => {
                                           openDrawing(drawing)
                                         }}
                            />
                          </Tooltip>
                        ]}
                      >
                        <Meta description={drawing.description} />
                      </Card>
                    </React.Fragment>
                  )
                }
              </div>
          }
        </div>
        {createModal && (
          <CreateModal
            toggleModal={toggleCreateDrawingModal}
            modal={createModal}
            onConfirm={handleCreateDrawing}
            resourceType={resource}
            confirmLoading={modalLoading}
          />
        )}
        {deleteModal && (
          <DeleteModal
            toggleModal={toggleDeleteDrawingModal}
            onConfirm={handleDeleteDrawing}
            modal={deleteModal}
            resourceType={resource}
            resourceName={currentDrawing.name}
            confirmLoading={modalLoading}
          />
        )}
        {duplicateModal && (
          <DuplicateDrawingModal
            toggleModal={toggleDuplicateDrawingModal}
            onConfirm={handleDuplicateDrawing}
            modal={duplicateModal}
            resourceType={resource}
            resourceName={currentDrawing.name}
            confirmLoading={modalLoading}
            currentProject={params.id}
          />
        )}
        {editModal && (
          <EditModal
            toggleModal={toggleEditDrawingModal}
            name={currentDrawing.name}
            description={currentDrawing.description}
            onConfirm={handleEditDrawing}
            modal={editModal}
            resourceType={resource}
            resourceName={currentDrawing.name}
            confirmLoading={modalLoading}
          />
        )}

        {showPdfModal &&
          <PDFExportModal
            showPdfModal={showPdfModal}
            toggleModal={togglePDFModal}
            pageOption={pageOption}
            setPageOption={setPageOption}
            exportProjectAsPDF={exportProjectAsPDF}
          />
        }
      </div>
    </div>
  )
}
