import fabric from './fabric.base.js'

let shouldCacheObject = fabric.Object.prototype.shouldCache

export const colorPropToSVG = (prop, value) => {
  if (!value) {
    return `${prop}: none; `;
  } else if (value.toLive) {
    return `${prop}: url(#SVGID_${value.id}); `;
  } else {
    const color = new fabric.Color(value),
      opacity = color.getAlpha();

    let str = `${prop}: ${color.toRgb()}; `;

    if (opacity !== 1) {
      //change the color in rgb + opacity
      str += `${prop}-opacity: ${opacity.toString()}; `;
    }
    return str;
  }
};

fabric.Rect.prototype.snapPoints = function(){
  let cr = this.calcACoords(true)
  // xPoints = [cr.tl.x, cr.tr.x, cr.bl.x, cr.br.x],
  // yPoints = [cr.tl.y, cr.tr.y, cr.bl.y, cr.br.y]
  //xMin = Math.min(...xPoints),
  //xMax = Math.max(...xPoints),
  //yMin = Math.min(...yPoints),
  //yMax = Math.max(...yPoints)
  // c = {x: xMin + (xMax - xMin) / 2,y: yMin + (yMax - yMin) / 2}

  return [
    { x: cr.tl.x,y: cr.tl.y, a: fabric.util.calcAngle(cr.tl, cr.tr) },
    { x: cr.tl.x,y: cr.tl.y, a: fabric.util.calcAngle(cr.tl, cr.bl) },
    { x: cr.tr.x,y: cr.tr.y, a: fabric.util.calcAngle(cr.tr, cr.tl) },
    { x: cr.tr.x,y: cr.tr.y, a: fabric.util.calcAngle(cr.tr, cr.br) },
    { x: cr.bl.x,y: cr.bl.y, a: fabric.util.calcAngle(cr.bl, cr.tl) },
    { x: cr.bl.x,y: cr.bl.y, a: fabric.util.calcAngle(cr.bl, cr.br) },
    { x: cr.br.x,y: cr.br.y, a: fabric.util.calcAngle(cr.br, cr.bl) },
    { x: cr.br.x,y: cr.br.y, a: fabric.util.calcAngle(cr.br, cr.tr) }
    // { x: c.x, y: c.y}
  ]
}

const ObjectExt = {
  getDefaults(){
    // let klass = fabric.classRegistry.getClass(this.type).defaults
    return {...fabric.Object.defaults, ...this.constructor.defaults }
  },
  snapPoints() {
    return []
  },
  /**
   * Returns styles-string for svg-export
   * @param {Boolean} skipShadow a boolean to skip shadow filter output
   * @return {String}
   */
  getSvgStyles(skipShadow) {
    const fillRule = this.fillRule ? this.fillRule : 'nonzero',
      strokeWidth = this.lineStrokeWidth || this.strokeWidth || '0',
      strokeDashArray = this.strokeDashArray ? this.strokeDashArray.join(' ') : 'none',
      strokeDashOffset = this.strokeDashOffset ? this.strokeDashOffset : '0',
      strokeLineCap = this.strokeLineCap ? this.strokeLineCap : 'butt',
      strokeLineJoin = this.strokeLineJoin ? this.strokeLineJoin : 'miter',
      strokeMiterLimit = this.strokeMiterLimit ? this.strokeMiterLimit : '4',
      opacity = typeof this.opacity !== 'undefined' ? this.opacity : '1',
      visibility = this.visible ? '' : ' visibility: hidden;',
      filter = skipShadow ? '' : this.getSvgFilter(),
      fill = colorPropToSVG('fill', this.fill),
      stroke = colorPropToSVG('stroke', this.stroke);

    return [
      stroke, 'stroke-width: ', strokeWidth, '; ',
      'stroke-dasharray: ', strokeDashArray, '; ',
      'stroke-linecap: ', strokeLineCap, '; ',
      'stroke-dashoffset: ', strokeDashOffset, '; ',
      'stroke-linejoin: ', strokeLineJoin, '; ',
      'stroke-miterlimit: ', strokeMiterLimit, '; ',
      fill, 'fill-rule: ', fillRule, '; ',
      'opacity: ', opacity, ';', filter, visibility,
    ].join('');
  },
  async getThumbnail (options = {}, output = null){

    let objectData = this.toObject()
    objectData.top = 0;
    objectData.left = 0;
    objectData.scaleX = 1
    objectData.scaleY = 1;

    let thumbnailObject = await fabric.Object.create(objectData)
    let bb = thumbnailObject.getBoundingRect(true,true)

    let width = options.width || bb.width
    let height = options.height || bb.height
    let scaleX = width / bb.width
    let scaleY = height / bb.height
    let scale = Math.min(scaleX,scaleY)

    let thumbnailCanvas= new fabric.Canvas()
    thumbnailCanvas.setWidth(bb.width * scale)
    thumbnailCanvas.setHeight(bb.height * scale)
    thumbnailCanvas.add(thumbnailObject)
    thumbnailObject.set({
      scaleX: scale,
      scaleY: scale
    })
    let bb2 = thumbnailObject.getBoundingRect(true,true)
    thumbnailObject.set({
      left: -bb2.left,
      top: -bb2.top
    })
    thumbnailObject.setCoords()
    thumbnailObject.dirty = true
    thumbnailCanvas.renderAll()
    return thumbnailCanvas.lowerCanvasEl
  },
  setHasBoundsControls (value) {
    this.hasBoundsControls = value
    if(!this._controlsVisibility){
      this._controlsVisibility = {}
    }
    let corners = ["mt", "mb", "mr", "ml", "tl", "tr", "bl", "br", "mtr"]

    for(let corner of corners){
      this._controlsVisibility[corner] = value
    }
  },

  getCornerEntries(){
    return Object.entries(this.oCoords);
  },
  //ordered controls
  _findTargetCorner(pointer, forTouch = false) {
    if (!this.hasControls || !this.canvas) {
      return '';
    }
    this.__corner = undefined;
    //modified
    const cornerEntries = this.getCornerEntries()

    for (let i = cornerEntries.length - 1; i >= 0; i--) {
      const [key, corner] = cornerEntries[i];
      if (this.controls[key].shouldActivate(key, this)) {
        const lines = this._getImageLines(
          forTouch ? corner.touchCorner : corner.corner
        );
        const xPoints = this._findCrossPoints(pointer, lines);
        if (xPoints !== 0 && xPoints % 2 === 1) {
          this.__corner = key;
          return key;
        }
      }
    }
    return '';
  },
  toLocalPoint(x,y){
    let inverseTransformMatrix = fabric.util.invertTransform(this.calcTransformMatrix(true, true));
    return fabric.util.transformPoint({x,y}, inverseTransformMatrix);
  },
  /**
   //  * Returns the point in local coordinates
   //  * @param {fabric.Point} point The point relative to the global coordinate system
   //  * @param {String} originX Horizontal origin: 'left', 'center' or 'right'
   //  * @param {String} originY Vertical origin: 'top', 'center' or 'bottom'
   //  * @return {fabric.Point}
   //  */
  // toLocalPoint (point, originX, originY) {
  //   var center = this.getCenterPoint(),
  //       p, p2;
  //
  //   if (typeof originX !== 'undefined' && typeof originY !== 'undefined' ) {
  //     p = this.translateToGivenOrigin(center, 'center', 'center', originX, originY);
  //   }
  //   else {
  //     p = new fabric.Point(this.left, this.top);
  //   }
  //
  //   p2 = new fabric.Point(point.x, point.y);
  //   if (this.angle) {
  //     p2 = fabric.util.rotatePoint(p2, center, -fabric.util.degreesToRadians(this.angle));
  //   }
  //   return p2.subtractEquals(p);
  // },
  setScaleX (value){
    value = this._constrainScale(value);
    if (value < 0) {
      this.flipX = !this.flipX;
      value *= -1;
    }
    this.scaleX = value
  },
  setScaleY (value){
    value = this._constrainScale(value);
    if (value < 0) {
      this.flipY = !this.flipY;
      value *= -1;
    }
    this.scaleY = value
  },
  setShadow (value){
    if (value && !(value instanceof fabric.Shadow)) {
      value = new fabric.Shadow(value);
    }
    this.shadow = value
  },
  _setExtra(key, value) {
    const isChanged = this[key] !== value;
    let fooName = "set" + key[0].toUpperCase() + key.slice(1)

    if (this[fooName]) {
      this[fooName](value)
    }
    else{
      this[key] = value;
    }

    if (isChanged) {
      const groupNeedsUpdate = this.group && this.group.isOnACache();
      if (
        (this.constructor).cacheProperties.includes(key)
      ) {
        this.dirty = true;
        groupNeedsUpdate && this.group.set('dirty', true);
      } else if (
        groupNeedsUpdate &&
        (this.constructor).stateProperties.includes(key)
      ) {
        this.group.set('dirty', true);
      }
    }
    return this;
  },
  setDirty(value = true) {
    this.dirty = value
    if(value){
      let group = this.group
      while(group){
        group.dirty = true
        group = group.group
      }
      this.canvas?.requestRenderAll()
    }
  },
  getAbsoluteAngle () {
    if(this.group) {
      let { tl, tr } = this.oCoords
      if (!tr || !tl) {
        return this.angle
      }
      return fabric.util.calcAngle(tl, tr)
    }
    else{
      return this.angle
    }
  },
  setAbsoluteThickness (value) {
    if(value < 0){
      value = 0
    }
    if(!this.height){
      return this.set('strokeWidth',value)
    }
    let k = this.getAbsoluteHeight() / this.height
    if(this.strokeUniform){
      k /= this.scaleY
    }
    this.set('strokeWidth',value / k)
  },
  getAbsoluteThickness () {
    if(!this.height){
      return this.strokeWidth
    }
    let k = this.getAbsoluteHeight() / this.height
    if(this.strokeUniform){
      k /= this.scaleY
    }
    return this.strokeWidth * k
  },
  getAbsoluteWidth () {
    let width = this.width || this.strokeWidth
    const matrix = this.calcTransformMatrix()
    const options = fabric.util.qrDecompose(matrix)
    return Math.abs(options.scaleX) * (width + this.strokeWidth)
  },
  getAbsoluteHeight () {
    let height = this.height || this.strokeWidth
    const matrix = this.calcTransformMatrix()
    const options = fabric.util.qrDecompose(matrix)
    return  Math.abs(options.scaleY) * (height + this.strokeWidth)
  },
  setAbsoluteHeight (value,keepRatio = this.lockAspectRatio) {
    if(!value){
      return
    }
    let absHeight = this.getAbsoluteHeight()
    let ownHeight = (this.height + this.strokeWidth) * this.scaleY
    let k = absHeight / ownHeight
    let desirableHeight = value / k


    if(this.resizable){
      let newHeight = desirableHeight - this.strokeWidth / k
      let ratio = newHeight / this.height
      this.height = newHeight
      if(keepRatio){
        this.width *= ratio
      }
    }
    else {
      let ratio = desirableHeight / ownHeight
      this._setExtra('scaleY', this.scaleY * ratio)
      if (keepRatio) {
        this._setExtra('scaleX', this.scaleX * ratio)
      }
      this._onScale()
    }
  },
  setAbsoluteWidth (value,keepRatio = this.lockAspectRatio) {
    if(!value){
      return
    }
    let absWidth = this.getAbsoluteWidth()
    let ownWidth = (this.width + this.strokeWidth) * this.scaleX
    let k = absWidth / ownWidth
    let desirableWidth = value / k

    if(this.resizable){
      let newWidth = desirableWidth - this.strokeWidth / k
      let ratio = newWidth / this.width
      this.width = newWidth
      if(keepRatio){
        this.height *= ratio
      }
    }
    else{
      let ratio = desirableWidth / ownWidth
      this._setExtra('scaleX',this.scaleX * ratio)
      if(keepRatio){
        this._setExtra('scaleY',this.scaleY * ratio)
      }
    }
    this._onScale()
  },
  resizable: false,
  setLocked(value) {
    this.locked = value
    if(this.constructor === fabric.ActiveSelection){
      for(let object of this._objects){
        object.lockMovementX = value
        object.lockMovementY = value
        object.lockRotation = value
        object.lockScalingX = value
        object.lockScalingY = value
        object.selectable = !value
      }
    }
    this.lockMovementX = value
    this.lockMovementY = value
    this.lockRotation = value
    this.lockScalingX = value
    this.lockScalingY = value
    this.selectable = !value
    if(value){
      this._onmouseclick = () => {
        if(!this.active){
          this.selectable = true
          this.canvas.setActiveObject(this)
          this.selectable = false
          this.canvas.renderAll()
        }
      }
      this.on("mouseclick",this._onmouseclick)
    }
    else{
      this.off("mouseclick",this._onmouseclick)
    }
  },

  flipDiagonally() {
    this.setExtra({
      flipY: !this.flipY,
      flipX: !this.flipX
    })
  },
  flipVertically() {
    this.setExtra('flipY',!this.flipY)
  },
  flipHorizontally() {
    this.setExtra('flipX',!this.flipX)
  },

  setAbsoluteAngle (value) {
    this.angle = 0
    this.setCoords()
    let zeroAbsAngle = this.getAbsoluteAngle()

    this.angle = 1
    this.setCoords()
    let oneAbsAngle = this.getAbsoluteAngle()

    let oneAngleDiff = oneAbsAngle - zeroAbsAngle

    this.angle = zeroAbsAngle + oneAngleDiff * value

    this.setCoords()
    this.setDirty()
  },
  getRelativeAngle () {
    let angle = 0;
    let group = this.group;
    while(group){
      angle += group.angle
      group = group.group
    }
    return fabric.util.normalizeAngle(angle);
  },

  shouldCache () {
    if (this.canvas?.exporting) return false
    return shouldCacheObject.call(this)
  },

  // let set = klass.prototype.set;
  getExtra (property) {
    let fooName = "get" + property[0].toUpperCase() + property.slice(1)
    if(this[fooName]){
      return this[fooName]()
    }
    return this[property];
  },
  setExtra (key, value) {

    if(this.__originalState){
      this.canvas?.fire("object:modified",{target: this})
    }

    let wasModified = this.__modified
    let __modified = false
    let original = {}
    let geometry, geometry2
    if(!wasModified){
      this.__modified = true
      if(key.constructor === Object){
        let values = {...key}
        for(let property in values){
          if(this[property] === values[property]){
            delete values[property]
          }
          else{
            original[property] = this.getExtra(property)
          }
        }
        __modified = Object.keys(values).length


        this.__originalState = original
        // if(this.canvas){
        //   this.canvas._currentTransform = {original}
        // }


        if(this.propertyPriorities){
          for(const propPriority of this.propertyPriorities){
            if(propPriority === "*"){
              for (const prop in values) {
                if(!this.propertyPriorities.includes(prop)){
                  this._setExtra(prop, values[prop]);
                }
              }
            }
            else{
              if(values[propPriority] !== undefined){
                this._setExtra(propPriority, values[propPriority]);
              }
            }
          }
        }
        else{
          for (const prop in values) {
            this._setExtra(prop, values[prop]);
          }
        }

      }else{
        let originalValue =this.getExtra(key)

        //if(originalValue !==value){

          original[key] = originalValue
          __modified = true

          this.__originalState = original
          // if(this.canvas) {
          //   this.canvas._currentTransform = { original }
          // }
          this._setExtra(key, value);

        //}

      }
      geometry = [this.left,this.top, this.flipX,this.flipY,this.width,this.height,this.scaleX,this.scaleY, this.angle,this.skewX,this.skewY].join(",")
    }


    if(!wasModified) {
      geometry2 = [this.left,this.top, this.flipX,this.flipY, this.width,this.height,this.scaleX,this.scaleY, this.angle,this.skewX,this.skewY].join(",")
      if (geometry !== geometry2) {
        __modified = true
        this.setCoords()
      }
      if(__modified){
        this.dirty = true
        this.fire("modified");
        this.canvas?.fire("object:modified", { target: this });
        this.canvas?.requestRenderAll()
      }
      delete this.__modified
    }
    if(this.canvas) {
      delete this.canvas._currentTransform
    }
    delete this.__originalState
    return this
  }
}

Object.assign(fabric.Object.prototype,ObjectExt)

export function makeFromObject(klass,parameters){
  let foo = klass.fromObject;
  klass.defaults = klass.defaults || {}
  klass.fromObject = function (options){
    options = {...(this.defaults || {}),...options}
    delete options.type
    return foo.call(klass,options).then((object) => {
      object.strokeUniform = false
      if(options.id) {
        object.id = options.id;
      }
      if(options.locked) {
        object.setLocked(options.locked)
      }
      if(object.__inititalize){
        object.__inititalize()
      }

      if(parameters?.properties){
        for(let property of parameters.properties){
          if(options[property]){
            object.set(property,options[property])
          }
        }
      }
      // let offset = object.cornerSize/2
      // let controls = object.controls
      // controls.mt.offsetY = -offset * (this.flipY? -1 : 1)
      // controls.mb.offsetY = offset* (this.flipY? -1 : 1)
      // controls.ml.offsetX = -offset* (this.flipX ? -1 : 1)
      // controls.mr.offsetX = offset* (this.flipX ? -1 : 1)
      //
      //
      // controls.tl.offsetX = -offset* (this.flipX ? -1 : 1)
      // controls.tr.offsetX = offset* (this.flipX ? -1 : 1)
      // controls.tl.offsetY = -offset* (this.flipY? -1 : 1)
      // controls.tr.offsetY = -offset* (this.flipY? -1 : 1)
      //
      // controls.bl.offsetX = -offset* (this.flipX ? -1 : 1)
      // controls.br.offsetX = offset* (this.flipX ? -1 : 1)
      // controls.bl.offsetY = offset* (this.flipY? -1 : 1)
      // controls.br.offsetY = offset* (this.flipY? -1 : 1)

      return object
    })
  }
  let to = klass.prototype.toObject;
  klass.prototype.toObject = function (propertiesToInclude){
    let object = to.call(this,propertiesToInclude)
    delete object["version"]

    let defaults = {...fabric.Object.defaults, ...klass.defaults}

    if(klass.storeProperties){
      for(let property of klass.storeProperties){


        //todo need to check all properties for getters
        // if(property === "cells"){
        //   object[property] = this.getCells()
        // }
        // else{
        object[property] = this[property]
        // }
      }
    }
    if(Object.keys(defaults).length){
      for (let property in object) {
        if(defaults[property] === "#000000"){

          if (object[property] ===  "#000000" || object[property] ===  "rgb(0,0,0)" || object[property] ===  "black") {
            delete object[property]
          }
        }
        else{

          if (object[property] === defaults[property]) {
            delete object[property]
          }
        }
      }
    }
    object.type = this.type;
    delete object.interactive
    if(this.id){
      object.id = this.id
    }
    return object
  }


}

// makeFromObject(fabric.Object)
makeFromObject(fabric.Group)
makeFromObject(fabric.Image)
makeFromObject(fabric.Triangle)
makeFromObject(fabric.Rect)
makeFromObject(fabric.Circle)
makeFromObject(fabric.Ellipse,{
  properties: ["startAngle",  "endAngle"]
})
makeFromObject(fabric.IText)
makeFromObject(fabric.Line)
makeFromObject(fabric.Path)
makeFromObject(fabric.Polygon)

fabric.Ellipse.prototype.resizable = true
fabric.Rect.prototype.resizable = true
fabric.Polygon.prototype.resizable = true
fabric.Path.prototype.resizable = false
fabric.Group.prototype.resizable = false


fabric.Object.prototype.strokeUniform = false
fabric.Ellipse.prototype.strokeUniform = false
fabric.Rect.prototype.strokeUniform = false
fabric.Polygon.prototype.strokeUniform = false
fabric.Path.prototype.strokeUniform = false
fabric.Group.prototype.strokeUniform = false

fabric.Ellipse.prototype.startAngle = 0
fabric.Ellipse.prototype.endAngle = 360
fabric.Ellipse.prototype._render = function(ctx) {
  ctx.beginPath();
  ctx.save();
  ctx.transform(1, 0, 0, this.ry / this.rx, 0, 0);

  ctx.arc(
    0,
    0,
    this.rx,
    fabric.util.degreesToRadians(this.startAngle),
    fabric.util.degreesToRadians(this.endAngle),
    false
  )

  ctx.restore();
  this._renderPaintInOrder(ctx);
}


fabric.Group.prototype._onScale = function() {
  if (this.canvas.preventScale ) {
    if (this.scaleX === this.scaleY && (this.scaleX  !== 1 || this.scaleY !== 1)) {
      this.width *= this.scaleX
      this.height *= this.scaleY
      for (let object of this._objects) {
        object.left *= this.scaleX
        object.top *= this.scaleY
        object.scaleX *= this.scaleX
        object.scaleY *= this.scaleY
        object._onScale()
      }
      this.scaleX = 1
      this.scaleY = 1
    }
  }
  this.dirty =true;
  this.canvas.requestRenderAll()
}

fabric.Polygon.prototype._onScale = function() {
  if (this.canvas.preventScale && this.resizable) {
    if (this.scaleX) {
      this.width *= this.scaleX
      for (let point of this.points) {
        point.x *= this.scaleX
      }
      this.pathOffset.x  *= this.scaleX

      this.scaleX = 1
    }
    if (this.scaleY) {
      this.height *= this.scaleY
      for (let point of this.points) {
        point.y *= this.scaleY
      }
      this.pathOffset.y  *= this.scaleY
      this.scaleY = 1
    }
  }
  this.dirty =true;
  this.canvas.requestRenderAll()
}

fabric.Ellipse.prototype._onScale = function() {
  if(this.canvas.preventScale && this.resizable){
    if(this.scaleX){
      this.width *= this.scaleX
      this.scaleX = 1
    }
    this.rx = this.width/2
    if(this.scaleY){
      this.height *= this.scaleY
      this.scaleY = 1
    }
    this.ry = this.height/2
  }
  this.dirty =true;
  this.canvas.requestRenderAll()
}

fabric.Object.prototype._onScale = function() {
  if(this.canvas.preventScale && this.resizable){
    if(this.scaleX){
      this.width *= this.scaleX
      this.scaleX = 1
    }
    if(this.scaleY){
      this.height *= this.scaleY
      this.scaleY = 1
    }
  }
  this.dirty =true;
  this.canvas.requestRenderAll()
}



fabric.Object.storeProperties = ["locked","lockAspectRatio"]


fabric.Object.defaults = {
  cornerSize: 9,
  touchCornerSize: 36,
  transparentCorners: false,
  scaleX: 1,
  scaleY: 1,
  locked: false,
  stroke: "#000000",
  fill: "rgb(0,0,0)",
  interactive: false,
  layout: "fit-content",
  subTargetCheck: false,
  originX: "left",
  originY: "top",
  strokeWidth: 0,
  strokeDashArray: null,
  strokeLineCap: "butt",
  strokeDashOffset: 0,
  strokeLineJoin: "miter",
  strokeUniform: false,
  strokeMiterLimit: 4,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 1,
  shadow: null,
  visible: true,
  backgroundColor: "",
  fillRule: "nonzero",
  paintFirst: "fill",
  globalCompositeOperation: "source-over",
  skewX: 0,
  skewY: 0
}

Object.defineProperty(fabric.Object.prototype, "type", {
  get () {
    const name = this.constructor.type;
    if (name === 'FabricObject') {
      return 'object';
    }
    return name.toLowerCase();
  },
  set(value) {}
})

fabric.Object.create = async function(object){
  let objects = await fabric.util.enlivenObjects([object])
  return objects[0]
}

fabric.Image.prototype.setElement = function (element, size) {
  this.removeTexture(this.cacheKey);
  this.removeTexture(`${this.cacheKey}_filtered`);
  this._element = element;
  this._originalElement = element;
  this._setWidthHeight(size);
  if(element){
    element.classList.add(Image.CSS_CANVAS);
    if (this.filters.length !== 0) {
      this.applyFilters();
    }
    // resizeFilters work on the already filtered copy.
    // we need to apply resizeFilters AFTER normal filters.
    // applyResizeFilters is run more often than normal filters
    // and is triggered by user interactions rather than dev code
    if (this.resizeFilter) {
      this.applyResizeFilters();
    }
  }
}


fabric.Canvas.prototype.preventScale = true