import React, { useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Drawings } from './components/explorer/DrawingsExplorer'
import { Drawer } from './components/drawer/drawer'
import { Projects } from './components/explorer/ProjectsExplorer'
import { DATAMODEL } from './database'

// function getDatabaseMode() {
//   return DATAMODEL.getDatabaseMode();
// }

function App() {
  // const [isOnline, setIsOnline] = useState(navigator.onLine);

  // useEffect(() => {
  //   const handleOnline = () => setIsOnline(true);
  //   const handleOffline = () => setIsOnline(false);
  //
  //   window.addEventListener('online', handleOnline);
  //   window.addEventListener('offline', handleOffline);
  //
  //   return () => {
  //     window.removeEventListener('online', handleOnline);
  //     window.removeEventListener('offline', handleOffline);
  //   };
  // }, []);

  // const [databaseMode, setDatabaseModeState] = useState(getDatabaseMode());


  // if(!DATAMODEL.initialized){
  //   DATAMODEL.init()
  // }

  // const clearLocalDatabase = () => {
  //   DATAMODEL.clear();
  // }
  // const handleDatabaseModeChange = (mode) => {
  //   setDatabaseModeState(mode);
  //   DATAMODEL.setDatabaseMode( mode);
  // };

  const onLoad = () => {}

  return (
    <div className="App">
      {/*<div className="status-bar-top" style={{ display: 'none' }}>*/}
      {/*  <span>Status: {isOnline ? 'Online' : 'Offline'}</span>*/}
      {/*  <div>*/}
      {/*    <label>*/}
      {/*      <input*/}
      {/*        type="radio"*/}
      {/*        value="default"*/}
      {/*        checked={databaseMode === 'default'}*/}
      {/*        onChange={() => handleDatabaseModeChange('default')}*/}
      {/*      />*/}
      {/*      Synced*/}
      {/*    </label>*/}
      {/*    <label>*/}
      {/*      <input*/}
      {/*        type="radio"*/}
      {/*        value="localStorage"*/}
      {/*        checked={databaseMode === 'localStorage'}*/}
      {/*        onChange={() => handleDatabaseModeChange('localStorage')}*/}
      {/*      />*/}
      {/*      Local*/}
      {/*    </label>*/}
      {/*    <label>*/}
      {/*      <input*/}
      {/*        type="radio"*/}
      {/*        value="firebase"*/}
      {/*        checked={databaseMode === 'firebase'}*/}
      {/*        onChange={() => handleDatabaseModeChange('firebase')}*/}
      {/*      />*/}
      {/*      Firebase*/}
      {/*    </label>*/}
      {/*    <button onClick={() => clearLocalDatabase()}>Reset</button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />*/}
          <Route path="/" element={<Projects />} />
          <Route path="/project/:id" element={<Drawings />} />
      <Route path="/board/*" element={<Drawer onLoad={onLoad} />} />
      {/* <Route exact path="/board/:id" element={<Dashboard onLoad={onLoad} saveState={false} />} /> */}
      {/* <Route exact path="/board/:id" element={<Board />} /> */}
    </Routes>
  </Router>
</div>
)
  ;
}

export default App;